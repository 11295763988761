import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import jwt from 'jsonwebtoken';
import 'react-toastify/dist/ReactToastify.css';
import "../components/font-awsome"
import { graphql } from "gatsby"
import {
  API_URL,
  PLAY_URL,
  DEMO_URL,
  LOBBY_VERSION,
  GAME_LIST,
  LOG_IN,
  LAUNCH_GAME,
  CENTRAL_URL,
  LOBBY_VERSION_V2
} from "gatsby-env-variables"
import useDarkMode from "use-dark-mode"
import { useLocation } from "@reach/router";

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import Slider from "~/components/slider"
import CategoryBar from "~/components/category-bar"
import RoundCheckBox from "~/components/round-checkbox"
import styled from "styled-components"
import filterIcon from "~/images/icon_filter.svg"
import maintenanceIcon from "~/images/icon_repair.png"
import comingsoonIcon from "~/images/icon_soon.png"
import hotIcon from "~/images/icon_fire.png"
import newIcon from "~/images/icon_new.png"
import featureIcon from "~/images/icon_feature.png"
import gameIcon from "~/images/icon_games.png"

const MainContent = styled.div`
  width: 100%;
  max-width: 1208px;
  margin: 0 auto;

  @media only screen and (max-width: 1248px) {
    padding: 0 20px;
  }
`

const Input = styled.input`
  width: 100%;
  height: 51px;
  background: #fff;
  border: 0.5px solid #707070;
  border-radius: 6px;
  backdrop-filter: blur(30px);
  padding: 0 27px;
  margin-top: 18px;

  @media screen and (max-width: 1023px) {
    height: 40px;
  }
`

const InnerContent = styled.div`
  margin-top: 28px;
`

const FilterPanel = styled.div`
  font-size: 14px;
  width: 128px;
  min-width: 128px;
  margin-right: 26px;
`

const CardWrapper = styled.div`
  margin-bottom: 70px;
`

const CheckBoxList = styled.div`
  font-size: 12px;
`

const CheckBoxHeading = styled.div`
  border-top: 0.5px solid #7e7e7e;
  margin-top: 12px;
`

const ShowMore = styled.button`
  width: 264px;
  height: 39px;
  margin: 0 auto 70px;
  font-size: 12px;
`

const ToolList = styled.div`
  font-size: 12px;
  margin-top: 26px;
`

const IconFilter = styled.div`
  width: 36px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  background: ${props => props.background};
  mask: url(${props => props.filterIcon}) no-repeat center / contain;
  -webkit-mask: url(${props => props.filterIcon}) no-repeat center / contain;
`

const TagWrapper = styled.div`
  font-size: 10px;

  div {
    padding: 2px 5px 2px 15px;
    margin: 0 0 0 auto;

    & + div {
      margin-top: 5px;
    }
  }
`

const GameStatusWrapper = styled.div`
  width: 100%;
  max-width: 188px;
  height: 4vw;
  max-height: 53px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
`

const TryNowWrapper = styled.div`
  width: 100%;
  max-width: 120px;
  height: 4vw;
  max-height: 53px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;

  /* img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    background: ${props => props.background};
    mask: url(${props => props.icon}) no-repeat center / contain;
    -webkit-mask: url(${props => props.icon}) no-repeat center / contain;
  } */
`

const IconGame = styled.div`
  background: ${props => props.background};
  mask: url(${props => props.iconSrc}) no-repeat center / contain;
  -webkit-mask: url(${props => props.iconSrc}) no-repeat center / contain;
  width: 20px;
  height: 20px;
  margin-right: 3px;
`

const SelectBox = styled.div`
  cursor: pointer;

  &:after {
    width: 0 !important;
  }

  .select-list {
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 100%);
  }
`

const Content = styled.div`
  transition: 2s;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 2;
`

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
    markdownRemark: {
      fields: { langKey },
      frontmatter
    },
    theme,
    iconWeb,
    common
  },
  pageContext,
}) => {
  const { favicon_logo } = common.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey).node.frontmatter
  const { value } = useDarkMode()
  const isDarkMode = value;
  const themeColor = theme.edges[0].node.frontmatter;
  const iconWebs = iconWeb.edges[0].node.frontmatter;
  const bannerList = edges
    .filter(item => item.node.file.split("/")[1] === langKey)
    .map(item => item.node)
  const [data, setData] = useState([])
  const [renderData, setRenderData] = useState([])
  const [lazyData, setLazyData] = useState([])
  const [perPage] = useState(24)
  const [skip, setSkip] = useState(0)
  const [categories, setCategories] = useState([])
  const [selected, setSelected] = useState({
    Cate: [],
    Prov: [],
    Feat: [],
  })
  const [listCate, setListCate] = useState([]);
  const [listProv, setListProv] = useState([]);
  const [listFeat, setListFeat] = useState([]);
  const [isSomeCate, setIsSomeCate] = useState(false);
  const [isSomeProv, setIsSomeProv] = useState(false);
  const [isSomeFeat, setIsSomeFeat] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [currentCate, setCurrentCate] = useState("All Games");
  const [isSearch, setIsSearch] = useState(false);
  const [currentFeat, setCurrentFeat] = useState("Featured");
  const [isShowSort, setIsShowSort] = useState(false);
  const [isFilterBox, setIsFilterBox] = useState(false);
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isModal, setIsModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [backLinks, setBackLink] = useState();
  const [cardNumber, setCardNumber] = useState();

  const handleMouseEnter = (index) => setCardNumber(index);
  const gameFilter = ['DARTSFEST', 'LEGENDOFDOLLY'];

  const openGames = (value) => {
    if (!value.status && value.demoUrl) {
      const playStaging = jwt.sign({
        link: value.demoUrl + `?lang=${langKey}`,
        provider: value.partner,
        gameName: value.name,
        mode: !isMobile ? 'desktop' : 'mobile',
        lang: langKey,
        backLink: backLinks,
        gameType: value.type,
        prefix: value.prefix,
      }, 'wrong-secret')
      const Features = 'top=0,left=0,height=768,width=1366,scrollbars=no'
      window.open(`${DEMO_URL}?link=${playStaging}`, Features, 'popup')
    }
  }

  const handleModal = () => {
    setIsModal(!isModal)
  }

  const authUser = () => {
    localStorage.clear();
    let dataSend = {
      username: username,
      password: password,
    }
    fetch(`${API_URL}${LOG_IN}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataSend)
    })
      .then(response => response.json())
      .then(responData => {
        if (responData.status.code === 0) {
          setIsModal(!isModal)
          let user = {
            token: responData.data.token,
            user: responData.data.user
          }
          window.localStorage.setItem('userData', JSON.stringify(user))
          toast.success(responData.status.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            toastId: 'success'
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(responData.status.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            toastId: 'error'
          });
        }
      })
  }

  const getLoobbyVersion = async () => {
    // const res = await fetch(`${CENTRAL_URL}${LOBBY_VERSION_V2}`)
  }

  useEffect(() => {
    const checkMobile =
      /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    setIsMobile(checkMobile)

    fetch(`${API_URL}${LOBBY_VERSION}`, {
      method: "POST",
      body: JSON.stringify()
    })
      .then(response => response.json())
      .then(({ data: { desktopLogin, mobileLogin } }) => {
        let backLink = isMobile ? mobileLogin : desktopLogin
        if (window.matchMedia("(display-mode: standalone)").matches) {
          window.location.href = mobileLogin
        }
        setBackLink(backLink)
      })
    fetch(`${API_URL}${GAME_LIST}`, { method: "POST" })
      .then(response => response.json())
      .then(resultData => {
        const res = resultData.data.map(item => ({
          image: item.image,
          name: item.name,
          kind: item.kind,
          partner: item.partner,
          status: item.status,
          type: item.type,
          tag: item.tag,
          prefix: item.prefix,
          url: item.url,
          demoUrl: item.demoUrl
        })).filter((items) => !gameFilter.includes(items.prefix))
        setData(res)
        setRenderData(res)
        const start = skip * perPage
        const end = skip * perPage + perPage
        setLazyData(res.slice(start, end))
      })
  }, [])

  const load = () => {
    setSkip(skip + 1)
  }

  useEffect(() => {
    const start = skip * perPage
    const end = skip * perPage + perPage
    const newLazyData = !skip
      ? [...renderData.slice(start, end)]
      : [...lazyData, ...renderData.slice(start, end)]
    setLazyData(newLazyData)
  }, [skip, renderData])

  useEffect(() => {
    filterCate()
  }, [currentCate])

  useEffect(() => {
    let newRenderData = []
    if (currentFeat === "Featured") {
      newRenderData = [...data]
    } else {
      const tagList = data.filter(item => item.tag.map(v => v.toLowerCase()).includes(currentFeat.toLowerCase()))
      const nonTagList = data.filter(item => !item.tag.map(v => v.toLowerCase()).includes(currentFeat.toLowerCase()))
      newRenderData = [...tagList, ...nonTagList]
    }
    setRenderData(newRenderData)
  }, [currentFeat])

  useEffect(() => {
    const type = data.map(item => item.type)
    const partner = data.map(item => item.partner)
    const feature = data
      .filter(item => item.tag.length && !item.tag.every(t => t === ""))
      .map(item => item.tag)
    const mapCate = type
      .filter((v, i) => type.indexOf(v) === i)
      .map(item => ({
        text: item,
        icon: item,
      }))
    const partnerList = partner.filter((v, i) => partner.indexOf(v) === i)
      .map(item => item.charAt(0).toUpperCase() + item.slice(1, item.length).toLowerCase())
    const mergedFeature = [].concat.apply([], feature)
    const featureList = mergedFeature.filter(
      (v, i) => mergedFeature.indexOf(v) === i
    ).map(item => item.charAt(0).toUpperCase() + item.slice(1, item.length).toLowerCase())
    setListFeat(featureList)
    setListProv(partnerList)
    setListCate(mapCate)
    setCategories([
      {
        text: "All Games",
        icon: "allgames",
      },
      ...mapCate,
      {
        text: "Search",
        icon: "search",
      },
    ])
    setSelected({ Cate: mapCate, Prov: partnerList, Feat: featureList })
  }, [data])

  useEffect(() => {
    let newRenderData = data.filter(
      item =>
        selected["Cate"]
          .map(cate => cate.text.toLowerCase())
          .includes(item.type.toLowerCase()) &&
        selected["Prov"].map(prov => prov.toLowerCase()).includes(item.partner.toLowerCase())
    )
    if (selected.Feat.length > 0 && selected.Feat.length < listFeat.length) {
      const featPrototype = selected.Feat.map(item => {
        return newRenderData.filter(value => {
          return value.tag.includes(item.toUpperCase())
        })
      })
      const mergedFeat = [].concat.apply([], featPrototype)
      newRenderData = mergedFeat.filter((v, i) => mergedFeat.indexOf(v) === i)
    }
    setRenderData(newRenderData)
    setSkip(0)
  }, [selected])

  const handleCheck = e => {
    setCurrentCate("All Games")
    const { className, id, checked } = e.target
    const isHeading = ["Category", "Provider", "Feature"].includes(id)
    const isCate = className === "Cate"
    const isFeat = className === "Feat"
    const list = isCate ? [...listCate] : isFeat ? [...listFeat] : [...listProv]
    const addList = isCate
      ? [
        ...selected[className],
        {
          text: id,
          icon: id,
        },
      ]
      : [...selected[className], id]
    const removeList = isCate
      ? selected[className].filter(item => item.text !== id)
      : selected[className].filter(item => item !== id)
    const newSelected = { ...selected }
    newSelected[className] =
      isHeading && checked
        ? list
        : isHeading && !checked
          ? []
          : checked
            ? addList
            : removeList
    setSelected(newSelected)
    const listCondition = isCate
      ? listCate.length
      : isFeat
        ? listFeat.length
        : listProv.length
    const setSomeCondition =
      newSelected[className].length < listCondition &&
      newSelected[className].length > 0
    if (isCate) {
      setIsSomeCate(setSomeCondition)
    } else if (isFeat) {
      setIsSomeFeat(setSomeCondition)
    } else {
      setIsSomeProv(setSomeCondition)
    }
  }

  const filterCate = () => {
    setSkip(0)
    const newRenderData = data.filter(item => {
      if (currentCate === "All Games") return item
      else return item.type === currentCate
    })
    setRenderData(newRenderData)
  }

  const handleFilterIcon = () => {
    setIsShowFilter(!isShowFilter)
  }

  const handleCate = cate => {
    setCurrentCate(cate)
  }

  const handleFilterBox = () => {
    setIsFilterBox(!isFilterBox)
  }

  const handleSearch = () => {
    if (isSearch) {
      filterCate()
    }
    setIsSearch(!isSearch)
  }

  const handleSearchText = value => {
    setSkip(0)
    const newRenderData = data.filter(item => {
      if (currentCate === "All Games")
        return item.name[langKey].toLowerCase().includes(value.toLowerCase())
      else
        return (
          item.type.toLowerCase() === currentCate.toLowerCase() &&
          item.name[langKey].toLowerCase().includes(value.toLowerCase())
        )
    })
    setRenderData(newRenderData)
  }

  return (
    <Layout pageContext={pageContext}>
      <ToastContainer />
      <SEO
        title={frontmatter.metadata.metaTitle}
        description={frontmatter.metadata.metaDescription}
        favicon_logo={favicon_logo}
      />
      <Slider bannerList={bannerList} theme={themeColor} />
      <MainContent>
        <CategoryBar
          categories={categories}
          currentCate={currentCate}
          handleCate={handleCate}
          handleSearch={handleSearch}
          isSearch={isSearch}
          handleSearchText={handleSearchText}
          frontmatter={frontmatter}
          theme={themeColor}
        />
        <ToolList className="flex justify-between">
          <div className="flex items-center md:hidden">
            <IconFilter
              alt=""
              onClick={handleFilterIcon}
              filterIcon={`/${iconWebs.sort}`}
              background={isDarkMode ? themeColor.dark_theme.background_color.fourth : themeColor.light_theme.background_color.fourth}
            />
            {isShowFilter ? frontmatter.filter_toggle : frontmatter.filter_show}
          </div>
          <div className="flex items-center lg:hidden">
            <IconFilter
              src={filterIcon}
              alt=""
              onClick={handleFilterBox}
              filterIcon={`/${iconWebs.sort}`}
              background={isDarkMode ? themeColor.dark_theme.background_color.fourth : themeColor.light_theme.background_color.fourth}
            />
            {'Filter'}
          </div>
          <div className="flex items-center">
            <SelectBox
              className="relative flex items-center select-box"
              onClick={() => setIsShowSort(!isShowSort)}
            >
              {frontmatter.sort} :
              <div className="font-bold" style={{ marginLeft: "10px" }}>
                {currentFeat}
              </div>
              <span className="block"></span>
              <div
                className={`select-list absolute ${isShowSort ? "block" : "hidden"}`}
              >
                {["Featured", ...listFeat].map((value, index) => (
                  <div
                    key={index}
                    className="flex items-center flex-1 h-full"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setCurrentFeat(value)}
                  >
                    {value}
                  </div>
                ))}
              </div>
            </SelectBox>
          </div>
        </ToolList>
        <InnerContent className="flex justify-between">
          <div className="md:hidden">
            <FilterPanel style={{ display: isShowFilter ? "block" : "none" }}>
              <div className="font-bold md:hidden">{frontmatter.filters}</div>
              <CheckBoxList>
                <CheckBoxHeading className="font-bold">
                  <RoundCheckBox
                    text={`Category`}
                    handleCheck={handleCheck}
                    isSome={isSomeCate}
                    check={selected["Cate"].length === listCate.length}
                    typeClass="Cate"
                  />
                </CheckBoxHeading>
                {listCate.map((value, index) => (
                  <RoundCheckBox
                    text={value.text}
                    key={index}
                    handleCheck={handleCheck}
                    check={selected["Cate"].some(
                      item => item.text === value.text
                    )}
                    typeClass="Cate"
                    isFront={true}
                  />
                ))}
              </CheckBoxList>
              <CheckBoxList>
                <CheckBoxHeading>
                  <RoundCheckBox
                    text={`Provider`}
                    handleCheck={handleCheck}
                    isSome={isSomeProv}
                    check={selected["Prov"].length === listProv.length}
                    typeClass="Prov"
                  />
                </CheckBoxHeading>
                {listProv.map((value, index) => (
                  <RoundCheckBox
                    text={value}
                    key={index}
                    handleCheck={handleCheck}
                    check={selected["Prov"].some(item => item === value)}
                    typeClass="Prov"
                    isFront={true}
                  />
                ))}
              </CheckBoxList>
              <CheckBoxList>
                <CheckBoxHeading>
                  <RoundCheckBox
                    text={`Feature`}
                    handleCheck={handleCheck}
                    isSome={isSomeFeat}
                    check={selected["Feat"].length === listFeat.length}
                    typeClass="Feat"
                  />
                </CheckBoxHeading>
                {listFeat.map((value, index) => (
                  <RoundCheckBox
                    text={value}
                    key={index}
                    handleCheck={handleCheck}
                    check={selected["Feat"].some(item => item === value)}
                    typeClass="Feat"
                    isFront={true}
                  />
                ))}
              </CheckBoxList>
            </FilterPanel>
          </div>
          <Content className="lg:hidden">
            <div className={isFilterBox ? "filter-wrapper" : "none"}>
              <div className="flex items-center justify-between title">
                <div>{frontmatter.contact_title}</div>
                <div className="close" onClick={() => handleFilterBox(false)}></div>
              </div>
              <FilterPanel style={{ display: isFilterBox ? "block" : "none" }}>
                <div className="font-bold md:hidden">{frontmatter.filters}</div>
                <CheckBoxList>
                  <CheckBoxHeading className="font-bold">
                    <RoundCheckBox
                      text={`Category`}
                      handleCheck={handleCheck}
                      isSome={isSomeCate}
                      check={selected["Cate"].length === listCate.length}
                      typeClass="Cate"
                    />
                  </CheckBoxHeading>
                  {listCate.map((value, index) => (
                    <RoundCheckBox
                      text={value.text}
                      key={index}
                      handleCheck={handleCheck}
                      check={selected["Cate"].some(
                        item => item.text === value.text
                      )}
                      typeClass="Cate"
                      isFront={true}
                    />
                  ))}
                </CheckBoxList>
                <CheckBoxList>
                  <CheckBoxHeading>
                    <RoundCheckBox
                      text={`Provider`}
                      handleCheck={handleCheck}
                      isSome={isSomeProv}
                      check={selected["Prov"].length === listProv.length}
                      typeClass="Prov"
                    />
                  </CheckBoxHeading>
                  {listProv.map((value, index) => (
                    <RoundCheckBox
                      text={value}
                      key={index}
                      handleCheck={handleCheck}
                      check={selected["Prov"].some(item => item === value)}
                      typeClass="Prov"
                      isFront={true}
                    />
                  ))}
                </CheckBoxList>
                <CheckBoxList>
                  <CheckBoxHeading>
                    <RoundCheckBox
                      text={`Feature`}
                      handleCheck={handleCheck}
                      isSome={isSomeFeat}
                      check={selected["Feat"].length === listFeat.length}
                      typeClass="Feat"
                    />
                  </CheckBoxHeading>
                  {listFeat.map((value, index) => (
                    <RoundCheckBox
                      text={value}
                      key={index}
                      handleCheck={handleCheck}
                      check={selected["Feat"].some(item => item === value)}
                      typeClass="Feat"
                      isFront={true}
                    />
                  ))}
                </CheckBoxList>
              </FilterPanel>
            </div>
          </Content>
          <div className="flex flex-col">
            <CardWrapper className="grid grid-cols-3 gap-6 lg:grid-cols-4">
              {lazyData.map((value, index) => (
                <div
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseEnter(!index)}
                  key={index}
                  className="relative flex flex-col items-center self-start justify-between h-full"
                >
                  <div>
                    <a className="cursor-pointer" onClick={() => openGames(value)}>
                      <img src={value.image.large} alt="" />
                    </a>
                  </div>
                  <div key={index} className="font-bold text-center">
                    {value.name[langKey]}
                  </div>
                  <TagWrapper
                    onClick={() => openGames(value)}
                    className={`absolute top-0 right-0 cursor-pointer ${value.tag[0] ? "block" : "hidden"
                      }`}
                  >
                    {value.tag.map((item, idx) => (
                      <div
                        key={idx}
                        className="table rounded"
                        style={{
                          backgroundColor:
                            item === "NEW"
                              ? "#13C750"
                              : item === "HOT"
                                ? "#FC4C60"
                                : "#7639FF",
                          color: "#fff",
                          backgroundImage:
                            item === "NEW"
                              ? `url(${newIcon})`
                              : item === "HOT"
                                ? `url(${hotIcon})`
                                : `url(${featureIcon})`,
                          backgroundSize: "10px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "3px center",
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </TagWrapper>
                  <GameStatusWrapper
                    style={{
                      display: value.status ? "flex" : "none",
                      color:
                        value.status === 900
                          ? "#FD3C84"
                          : value.status === 901
                            ? "#FD9A00"
                            : "#000",
                    }}
                    className="items-center justify-center font-bold game-status"
                  >
                    <div className="flex items-center justify-center">
                      <div>
                        <img
                          src={
                            value.status === 900
                              ? maintenanceIcon
                              : comingsoonIcon
                          }
                          alt=""
                          style={{
                            width: value.status === 901 ? "26px" : "20px",
                          }}
                        />
                      </div>
                      <div>
                        {value.status === 900
                          ? "Maintenance"
                          : value.status === 901
                            ? "Coming Soon!"
                            : ""}
                      </div>
                    </div>
                  </GameStatusWrapper>
                  {!isMobile &&
                    <TryNowWrapper
                      onClick={() => openGames(value)}
                      style={{
                        display: (index === cardNumber) && value.demoUrl ? "flex" : "none",
                        color: `${themeColor.light_theme.font_color.third}`
                      }}
                      className="items-center justify-center font-bold cursor-pointer game-status">
                      <div className="flex items-center justify-center">
                        <IconGame
                          background={isDarkMode ? themeColor.dark_theme.background_color.fourth : themeColor.light_theme.background_color.fourth}
                          iconSrc={`/${iconWebs.game}`}
                        />
                        <div>
                          {frontmatter.try_now}
                        </div>
                      </div>
                    </TryNowWrapper>
                  }
                </div>
              ))}
            </CardWrapper>
            {skip * perPage + perPage < renderData.length && (
              <ShowMore
                onClick={load}
                className="font-bold text-black bg-white rounded hover:bg-blue-700 hover:text-white show-more"
              >
                {frontmatter.show_more}
              </ShowMore>
            )}
          </div>
        </InnerContent>
      </MainContent>
    </Layout>
  )
}

export const query = graphql`
  query IndexPageTemplate($langKey: String!) {
    allMarkdownRemark(
      filter: {
        collection: {eq: "banner"}
      }
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          file
          frontmatter {
            image
            dot_thumbnail
            banner_text
            button_text
          }
        }
      }
    }

    markdownRemark(
      frontmatter: {templateKey: {eq: "index"}}
      fields: {langKey: {eq: $langKey}}
    ) {
      id
      frontmatter {
        metadata {
          metaDescription
          metaTitle
        }
        filter_toggle
        filter_show
        show_more
        filters
        sort
        signin
        login
        cancel
        username
        password
        search_label
        search_button
        try_now
        enable_menu
      }
      fields {
        langKey
      }
    }

    theme: allMarkdownRemark(filter: {collection: {eq: "theme"}}) {
      edges {
        node {
          file
          frontmatter {
            light_theme {
              background_color {
                primary
                secondary
                third
                fourth
              }
              font_color {
                primary
                secondary
                third
              }
            }
            dark_theme {
              background_color {
                primary
                secondary
                third
                fourth
              }
              font_color {
                primary
                secondary
                third
              }
            }
          }
        }
      }
    }

    iconWeb: allMarkdownRemark(filter: {collection: {eq: "icons"}}) {
      edges {
        node {
          id
          frontmatter {
            question
            sort
            sun
            gift
            play
            game
          }
        }
      }
    }

    common: allMarkdownRemark(filter: {collection: {eq: "common"}}) {
      edges {
        node {
          file
          frontmatter {
            favicon_logo
          }
        }
      }
    }
  }
`

export default IndexPage
